import { takeEvery, put, call } from 'redux-saga/effects';

import { createBrowserHistory } from 'history';

//i18n
import i18n from 'i18n';

// Auth redux actions
import { loginSuccessAction, logoutSuccessAction, authErrorAction } from 'store/auth/actions';

// API calls
import {
	api_login,
	api_logout,
	api_salesperson_login,
	api_getSalesPersonCustomers,
	api_createImpersonation
} from 'backend/api_calls';

// User object via local store
import UserSession from 'backend/user_session';
import ShopCart from 'backend/shop_cart';

function* loginCustomer(action) {
	try {
		// First try to login as sales person
		try {
			const salesResponse = yield call(api_salesperson_login, action.args);

			UserSession.setSalesPersonLoginData(salesResponse);

			// Salesperson login success, impersonate first linked customer!
			const customerData = yield call(api_getSalesPersonCustomers);

			if (customerData != null && customerData.items.length > 0) {
				let cid = customerData.items[0].id;
				UserSession.setLinkedCustomers(customerData);

				api_createImpersonation(cid)
					.then(response => {
						const link = document.createElement('a');
						link.href = response.redirect_url + '?isSales=1';
						document.body.appendChild(link);
						link.click();
					})
					.catch(error => console.error(error));

				return;
			} else {
				yield put(authErrorAction(i18n.t('salesperson_no_customer_link_error')));
				return;
			}
		} catch (e) {
			console.log('Sales auth error: ' + e);
		}

		// Fallback to normal customer login flow
		const response = yield call(api_login, action.args);

		// Reset sales person state
		UserSession.setSalesPersonLoginData(null);
		UserSession.setLinkedCustomers(null);
		// Save customer state
		UserSession.initWithJson(response);

		// Restore customer's cart
		ShopCart.restoreCartForUser(UserSession.getId());

		yield put(loginSuccessAction(response, 'Welcome back ' + UserSession.getName()));

		// Goto profile page after successful login
		//createBrowserHistory().push("/profile");
		//window.location.reload();
	} catch (error) {
		console.log('Login error: ' + error);
		yield put(authErrorAction(error));
	}
}

function* logoutCustomer(action) {
	try {
		let args = {
			at: UserSession.getAccessToken(),
			rt: UserSession.getRefreshToken()
		};

		// Store active customer's cart
		ShopCart.storeCartForUser(UserSession.getId());

		// For now clear cart on logout
		ShopCart.clear();
		UserSession.clearData();

		// Reset sales person state
		UserSession.setSalesPersonLoginData(null);
		UserSession.setLinkedCustomers(null);

		if (args.at != null && args.rt != null) {
			const response = yield call(api_logout, args);
			yield put(logoutSuccessAction(response));
		}
	} catch (error) {
		yield put(authErrorAction(error));
	} finally {
		// Goto home page
		createBrowserHistory().push('/');
		window.location.reload();
	}
}

function* authSaga() {
	yield takeEvery('login', loginCustomer);
	yield takeEvery('logout', logoutCustomer);
}

export default authSaga;
