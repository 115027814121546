import { takeEvery, put, call, takeLatest } from 'redux-saga/effects';

//import { useNavigate } from "react-router-dom";

// Checkout redux actions
import {
	cartUpdateSuccessAction,
	checkoutErrorAction,
	orderCompleteSuccessAction,
	clearCartAction
} from 'store/checkout/actions';

// API calls
import { api_saveOrder } from 'backend/api_calls';

// ShopCart object via local store
import ShopCart from 'backend/shop_cart';
//import UserSession from "backend/user_session"

function* addToCart(action) {
	try {
		// Note: for now we do not need to call the server / API to calculate amounts etc.
		//const response = yield call(api_login, action.args);

		ShopCart.addOrUpdate(action.args);

		yield put(
			cartUpdateSuccessAction(
				'Item added ' + action.args.bcd + ' | ' + action.args.quantity,
				ShopCart.getTotalQuantity()
			)
		);
	} catch (error) {
		yield put(checkoutErrorAction(error));
	}
}

function* restoreCart(action) {
	try {
		var items = [];

		for (let product of action.args.items) {
			var dimension = {};

			for (let dim of product.dimensions) {
				if (dim.id == product.oline.pdid) {
					dimension = dim;
					break;
				}
			}

			// Dispatch add to cart action
			const token = {
				id: product.id,
				pid: product.id,
				did: product.oline.pdid,
				rcid: product.cdata?.rcid,
				rcnm: product.cdata?.rcnm,
				cd: product.cd,
				bcd: dimension.bcd,
				quantity: product.oline.qty,
				amount: product.prc,
				amountbd: product.prcbd,
				amount2: product.prc2,
				nm: product.nm,
				img1: product.img1,
				//color: color,
				//size: size,
				pRef: product
			};
			items.push(token);
		}
		ShopCart.internalStoreItems(items);

		yield put(cartUpdateSuccessAction('Shop cart restored', ShopCart.getTotalQuantity()));
	} catch (error) {
		yield put(checkoutErrorAction(error));
	}
}

function* removeFromCart(action) {
	try {
		ShopCart.remove(action.args);

		yield put(cartUpdateSuccessAction('Item removed ' + action.args.bcd, ShopCart.getTotalQuantity()));
	} catch (error) {
		yield put(checkoutErrorAction(error));
	}
}

function* clearCart(action) {
	try {
		ShopCart.clear();

		//yield put(cartUpdateSuccessAction("Items cleared"));
	} catch (error) {
		yield put(checkoutErrorAction(error));
	}
}

function* processOrder(action) {
	try {
		const response = yield call(api_saveOrder, action.order);

		yield put(clearCartAction());
		yield put(orderCompleteSuccessAction(response));
	} catch (error) {
		yield put(checkoutErrorAction(error));
	}
}

function* checkoutSaga() {
	yield takeEvery('add-to-cart', addToCart);
	yield takeEvery('restore-cart', restoreCart);
	yield takeEvery('remove-from-cart', removeFromCart);
	yield takeLatest('clear-cart', clearCart);
	yield takeLatest('order-completed', processOrder);
}

export default checkoutSaga;
